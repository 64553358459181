<template>
  <div class="attendance-page">
    <v-header :params="{ title, leftIcon: true }"></v-header>
    <div class="headText">
      <div class="bottom_Mes" @click="attendance">
        <p>已获得星星</p>
        <p>{{ this.getStar }}</p>
      </div>
    </div>
    <div class="attendance_main_box">
      <div class="lineContain1">
        <div v-for="(item,index) in blockList1" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
      <div class="lineContain2">
        <div v-for="(item,index) in middleBlock1" :key="index" :class="item">
        </div>
      </div>
      <div class="lineContain1">
        <div v-for="(item,index) in blockList2" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
      <div class="lineContain2">
        <div v-for="(item,index) in middleBlock2" :key="index" :class="item">
        </div>
      </div>
      <div class="lineContain1">
        <div v-for="(item,index) in blockList3" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
      <div class="lineContain2">
        <div v-for="(item,index) in middleBlock3" :key="index" :class="item">
        </div>
      </div>
      <div class="lineContain1">
        <div v-for="(item,index) in blockList4" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
      <div class="lineContain2">
        <div v-for="(item,index) in middleBlock4" :key="index" :class="item">
        </div>
      </div>
      <div class="lineContain1">
        <div v-for="(item,index) in blockList5" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
      <div class="lineContain2">
        <div v-for="(item,index) in middleBlock5" :key="index" :class="item">
        </div>
      </div>
      <div class="lineContain1">
        <div v-for="(item,index) in blockList6" :key="index" :class="selectClass(item.isAttendance,index)">
          <p v-if="index % 2 ===0">+{{ item.addStart }}</p>
          <p v-if="index % 2 ===0">第{{ item.dayNumber }}天</p>
        </div>
      </div>
    </div>
    <div class="attendacne_message">
      <button class="attendance_btn" @click="attendance">
        {{ attendanceDisable ? '已签到' : '签到' }}
      </button>
      <p>已连续签到{{ this.attendance_day }}天 继续加油！</p>
    </div>

  </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils.js";
export default {
  components: {
    vHeader,
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    if (JSON.stringify(this.$store.state.common.loginInfo) === '{}'){
      this.$toast('请先登录');
      this.$router.push({name:'login',params:{return_url:'attendance'}});
      return;
    }
    if (this.$store.state.common.loginInfo.access_token === null || this.$store.state.common.loginInfo.access_token === ''){
      this.$toast('请先登录');
      this.$router.push({name:'login',params:{return_url:'attendance'}});
      return;
    }
    if (this.$store.state.common.loginInfo.userId === null || this.$store.state.common.loginInfo.userId === ''){
      this.$toast('请先登录');
      this.$router.push({name:'login',params:{return_url:'attendance'}});
      return;
    }
    this.getData();
  },
  data(){
    return{
      title: this.$route.meta.title,
      mes: [1,2,3,4,5,6,7,8,9],
      attendance_record:[],
      attendanceDisable: false,
      attendance_day: 0,
      getStar: 0,
      blockList1: [],
      blockList2: [],
      blockList3: [],
      blockList4: [],
      blockList5: [],
      blockList6: [],
      middleBlock1: [],
      middleBlock2: [],
      middleBlock3: [],
      middleBlock4: [],
      middleBlock5: [],
    }
  },
  methods:{
    async attendance(){
      const res = await this.$HTTP.post(this,'API_ATTENDANCE_ONE',{userId:this.$store.state.common.loginInfo.userId},{},true);
      this.attendance_record = [];
      this.getStar = 0;
      this.attendance_day = 0;
      this.blockList1 = [];
      this.blockList2 = [];
      this.blockList3 = [];
      this.blockList4 = [];
      this.blockList5 = [];
      this.blockList6 = [];
      this.middleBlock1 = [];
      this.middleBlock2 = [];
      this.middleBlock3 = [];
      this.middleBlock4 = [];
      this.middleBlock5 = [];
      this.setData(res.data);
      this.$toast('签到成功');
      this.$forceUpdate();
    },
    async getData(){
      this.attendance_record = [];
      this.getStar = 0;
      this.attendance_day = 0;
      const res = await this.$HTTP.post(this,'API_ATTENDANCE_RECORD',{userId:this.$store.state.common.loginInfo.userId},{},true);
      this.setData(res.data);
    },
    setData(dataList){
      if (dataList.isAttendance === 0){
        this.attendanceDisable = false;
      } else {
        this.attendanceDisable = true;
      }
      this.attendance_day = dataList.dayNum;
      let dayNum = dataList.dayNum;
      for (let i=0;i<30;i++){
        let isAttendance = false;
        let addStart = 0;
        if (i < 6){
          addStart = dataList.firstDayNum + (i * dataList.addNum);
        } else {
          addStart = dataList.firstDayNum + (6 * dataList.addNum);
        }
        if (i === 6){
          addStart = addStart + dataList.firstWeekNum;
        }
        if (i === 13){
          addStart = addStart + dataList.secondWeekNum;
        }
        if (i === 20){
          addStart = addStart + dataList.thirdWeekNum;
        }
        if (i === 29){
          addStart = addStart + dataList.fourthWeekNum;
        }
        if (dayNum !== 0){
          isAttendance = true;
          dayNum = dayNum - 1;
          this.getStar = this.getStar + addStart;
        }
        let attendance_record_data = {
          isAttendance: isAttendance,
          addStart: addStart,
          dayNumber: i + 1
        }
        this.attendance_record.push(attendance_record_data);
      }
      this.attendance_record.forEach((item,index) =>{
        if (index >= 0 && index < 5){
          this.blockList1.push(item);
          if (index !== 4){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList1.push(undueBlock);
          }
        }
        if (index >= 5 && index < 10){
          this.blockList2.push(item);
          if (index !== 9){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList2.push(undueBlock);
          }
        }
        if (index >= 10 && index < 15){
          this.blockList3.push(item);
          if (index !== 14){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList3.push(undueBlock);
          }
        }
        if (index >= 15 && index < 20){
          this.blockList4.push(item);
          if (index !== 19){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList4.push(undueBlock);
          }
        }
        if (index >= 20 && index < 25){
          this.blockList5.push(item);
          if (index !== 24){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList5.push(undueBlock);
          }
        }
        if (index >= 25 && index < 30){
          this.blockList6.push(item);
          if (index !== 29){
            let isAttendance = false;
            if (this.attendance_record[index + 1].isAttendance && item.isAttendance){
              isAttendance = true;
            }
            let undueBlock = {
              isAttendance: isAttendance,
              addStart: ''
            }
            this.blockList6.push(undueBlock);
          }
        }
      })
      this.blockList2 = this.blockList2.reverse();
      this.blockList4 = this.blockList4.reverse();
      this.blockList6 = this.blockList6.reverse();
      //中间层过度条样式设置
      for (let i=0;i<5;i++){
        if (i !== 4){
          this.middleBlock1.push('hideBlock');
        } else {
          if (this.blockList1[8].isAttendance && this.blockList2[8].isAttendance){
            this.middleBlock1.push('showCheckBlock');
          } else {
            this.middleBlock1.push('showBlock');
          }
        }
      }
      for (let i=0;i<5;i++){
        if (i !== 0){
          this.middleBlock2.push('hideBlock');
        } else {
          if (this.blockList2[0].isAttendance && this.blockList3[0].isAttendance){
            this.middleBlock2.push('showCheckBlock');
          } else {
            this.middleBlock2.push('showBlock');
          }
        }
      }
      for (let i=0;i<5;i++){
        if (i !== 4){
          this.middleBlock3.push('hideBlock');
        } else {
          if (this.blockList3[8].isAttendance && this.blockList4[8].isAttendance){
            this.middleBlock3.push('showCheckBlock');
          } else {
            this.middleBlock3.push('showBlock');
          }
        }
      }
      for (let i=0;i<5;i++){
        if (i !== 0){
          this.middleBlock4.push('hideBlock');
        } else {
          if (this.blockList4[0].isAttendance && this.blockList5[0].isAttendance){
            this.middleBlock4.push('showCheckBlock');
          } else {
            this.middleBlock4.push('showBlock');
          }
        }
      }
      for (let i=0;i<5;i++){
        if (i !== 4){
          this.middleBlock5.push('hideBlock');
        } else {
          if (this.blockList5[8].isAttendance && this.blockList6[8].isAttendance){
            this.middleBlock5.push('showCheckBlock');
          } else {
            this.middleBlock5.push('showBlock');
          }
        }
      }
    },
  },
  computed:{
    selectClass(){
      return function (isAttendance,num) {
        if (num % 2 !== 0){
          if (isAttendance){
            return 'undueCheckDiv';
          }
          return 'undueDiv';
        } else {
          if (isAttendance){
            return 'checkDiv';
          }
          return "noCheckDiv";
        }
      }
    },
  }
}
</script>
<style lang="less" scoped>
@media only screen and (max-height: 870px){
  .attendance-page{
    background-color: #8080801c;
    height: 100%;
    width: 100%;
    position: fixed;

    .headText{
      width: 100%;
      height: 20%;
      background-color: #e0675e;
      text-align: center;
      padding-top: 15px;

      .bottom_Mes{
        width: 50%;
        margin: auto;
        background-color: white;
        border-radius: 30px;
        text-align: center;

        p:nth-child(1){
          padding-top: 8px;
          margin: 0;
          font-size: 10px;
          font-weight: 600;
        }

        p:nth-child(2){
          padding-top: 5px;
          margin: 0;
          font-size: 25px;
          font-weight: 600;
          color: #e0675e;
        }
      }
    }
    .attendance_main_box{
      position: relative;
      top: -9%;
      width: 90%;
      text-align: center;
      border-radius: 20px;
      background-color: white;
      margin: 10px auto;
      padding: 6px 0px;

      .checkDiv{
        width: 1.13rem;
        height: 1.13rem;
        display: inline-block;
        margin: 0.22rem;
        border-radius: 30%;
        background-color: #e0675e;
        color: white;
        position: relative;
        z-index: 2;
        border: solid;
        border-color: #e0675e;
        border-width: 1.8px;

        p:nth-child(1){
          margin: 0;
          margin-top: 5px;
          height: 40%;
          font-size: 0.4rem;
        }
        p:nth-child(2){
          margin: 0;
          height: 40%;
          font-size: 0.3rem;
        }
      }

      .undueDiv{
        width: 0.8rem;
        height: 0.001rem;
        display: inline-block;
        margin: auto -19px;
        padding: 0.16rem;
        background-color: #dcdee0;
        color: #dcdee0;
        z-index: 1;

        p{
          margin: auto 0;
          padding: 0;
        }
      }

      .undueCheckDiv{
        width: 0.8rem;
        height: 0.001rem;
        display: inline-block;
        margin: auto -19px;
        padding: 0.16rem;
        background-color: #e0675e;
        color: #e0675e;
        z-index: 1;

        p{
          margin: auto 0;
          padding: 0;
        }
      }

      .noCheckDiv{
        width: 1.13rem;
        height: 1.13rem;
        display: inline-block;
        margin: 0.22rem;
        border-radius: 30%;
        background-color: #f7f8fa;
        color: #323233;
        position: relative;
        z-index: 2;
        border: solid;
        border-color: gray;
        border-width: 1.8px;

        p:nth-child(1){
          margin: 0;
          margin-top: 5px;
          height: 40%;
          font-size: 0.4rem;
        }
        p:nth-child(2){
          margin: 0;
          height: 40%;
          font-size: 0.3rem;
        }
      }

      .lineContain1{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
      }

      .lineContain2{
        margin: -19px auto;
        padding: 0.24rem 0.08rem;
        display: flex;
        justify-content: center;

        div{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #dcdee0;
          color: #dcdee0;
          display: inline-block;
        }

        .hideBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: white;
          color: white;
          display: inline-block;
        }

        .showBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #dcdee0;
          color: #dcdee0;
          display: inline-block;
        }

        .showCheckBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #e0675e;
          color: #e0675e;
          display: inline-block;
        }
      }

    }

    .attendacne_message{
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      position: relative;
      top: -9%;
      text-align: center;

      .attendance_btn{
        display: inline-block;
        height: 1.6rem;
        width: 60%;
        margin-top: 0.06667rem;
        border-radius: 0.8rem;
        background-color: #e0675e;
        color: white;
        border: none;
      }

      p{
        margin: 0;
        padding: 10px 0px;
        font-size: 0.3rem;
        color: black;
        font-weight: 600;
      }
    }
  }
}
@media only screen and (min-height: 870px){
  .attendance-page{
    background-color: #8080801c;
    height: 100%;
    width: 100%;
    position: fixed;

    .headText{
      width: 100%;
      height: 20%;
      background-color: #e0675e;
      text-align: center;
      padding-top: 15px;

      .bottom_Mes{
        width: 70%;
        margin: auto;
        background-color: white;
        border-radius: 30px;
        text-align: center;

        p:nth-child(1){
          padding-top: 9px;
          margin: 0;
          font-size: 13px;
          font-weight: 600;
        }

        p:nth-child(2){
          padding-top: 9px;
          margin: 0;
          font-size: 32px;
          font-weight: 600;
          color: #e0675e;
        }
      }
    }
    .attendance_main_box{
      position: relative;
      top: -8%;
      width: 90%;
      text-align: center;
      border-radius: 20px;
      background-color: white;
      margin: 10px auto;
      padding: 6px 0px;

      .checkDiv{
        width: 1.13rem;
        height: 1.13rem;
        display: inline-block;
        margin: 0.22rem;
        border-radius: 30%;
        background-color: #e0675e;
        color: white;
        position: relative;
        z-index: 2;
        border: solid;
        border-color: #e0675e;
        border-width: 1.8px;

        p:nth-child(1){
          margin: 0;
          margin-top: 5px;
          height: 40%;
          font-size: 0.4rem;
        }
        p:nth-child(2){
          margin: 0;
          height: 40%;
          font-size: 0.3rem;
        }
      }

      .undueDiv{
        width: 0.8rem;
        height: 0.001rem;
        display: inline-block;
        margin: auto -19px;
        padding: 0.16rem;
        background-color: #dcdee0;
        color: #dcdee0;
        z-index: 1;

        p{
          margin: auto 0;
          padding: 0;
        }
      }

      .undueCheckDiv{
        width: 0.8rem;
        height: 0.001rem;
        display: inline-block;
        margin: auto -19px;
        padding: 0.16rem;
        background-color: #e0675e;
        color: #e0675e;
        z-index: 1;

        p{
          margin: auto 0;
          padding: 0;
        }
      }

      .noCheckDiv{
        width: 1.13rem;
        height: 1.13rem;
        display: inline-block;
        margin: 0.22rem;
        border-radius: 30%;
        background-color: #f7f8fa;
        color: #323233;
        position: relative;
        z-index: 2;
        border: solid;
        border-color: gray;
        border-width: 1.8px;

        p:nth-child(1){
          margin: 0;
          margin-top: 5px;
          height: 40%;
          font-size: 0.4rem;
        }
        p:nth-child(2){
          margin: 0;
          height: 40%;
          font-size: 0.3rem;
        }
      }

      .lineContain1{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
      }

      .lineContain2{
        margin: -19px auto;
        padding: 0.24rem 0.08rem;
        display: flex;
        justify-content: center;

        div{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #dcdee0;
          color: #dcdee0;
          display: inline-block;
        }

        .hideBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: white;
          color: white;
          display: inline-block;
        }

        .showBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #dcdee0;
          color: #dcdee0;
          display: inline-block;
        }

        .showCheckBlock{
          margin: auto;
          padding: 0;
          width: 0.3rem;
          height: 0.5rem;
          background-color: #e0675e;
          color: #e0675e;
          display: inline-block;
        }
      }

    }

    .attendacne_message{
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      position: relative;
      top: -8%;
      text-align: center;

      .attendance_btn{
        display: inline-block;
        height: 1.6rem;
        width: 60%;
        margin-top: 0.06667rem;
        border-radius: 0.8rem;
        background-color: #e0675e;
        color: white;
        border: none;
      }

      p{
        margin: 0;
        padding: 10px 0px;
        font-size: 0.3rem;
        color: black;
        font-weight: 600;
      }
    }
  }
}
</style>